import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthContext from '../../components/AuthContext/AuthContext';
import CertModalChange from '../../components/CertModalChange/CertModalChange';

function ListenerCerts(props) {
    let { userid, issuingStatus, setIssuingStatus } = props;
    const [listenerCerts, setListenerCerts] = useState([]);
    const { fetchCerts } = useContext(AuthContext);
    const [showModal2, setShowModal2] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                let tmpArray= [];

                const response = await fetchCerts(userid);
                if (response.length > 0) {
                    setListenerCerts(response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userid, issuingStatus]);

    // const handleChange = async (e, id) => {
    //     e.preventDefault();

    //     const jwttoken = localStorage.getItem("token");
    //     if (!jwttoken) {
    //         console.error("JWT TOKEN IS NOT AVAILABLE");
    //         return;
    //     }
    //     const headers = {
    //         'Authorization': `Bearer ${jwttoken}`,
    //         'Content-Type': 'application/json'
    //     }
    //     const response = await axios.get(`https://rmacerts.ripov-edu.kz/api/deleteCert/${id}`, { headers });
    //     if (response.status >= 200 && response.status < 300) {
    //         alert("Удалено");
    //         const updResponse = await fetchCerts(userid);
    //         setListenerCerts(updResponse);
    //     }
    // }
    const handleOpenModal = (e, course) => {
        e.preventDefault();
        setSelectedCourse(course);
        setShowModal2(true);
    };

    const handleCloseModal = (changeTranscript = null) => {
        setSelectedCourse(null);
        setShowModal2(false);
        if (changeTranscript)
            changeTranscript(false);
    };

    const handleWaSend = async (e, item) => {
        e.preventDefault();
        try {

            const jwttoken = localStorage.getItem("token");

            if (!jwttoken) {
                console.error("JWT TOKEN IS NOT AVAILABLE");
                return;
            }

            const waHeaders = {
                    'Authorization': `Bearer ${jwttoken}`,
                    'Content-Type': 'application/json'
            };

            var trimmedUrl = item.FileLink.replace(/https?:\/\/rmacerts\.ripov-edu\.kz\/api\/pdf\//, '');
            var trimmedTranscriptUrl = item.TranscriptLink ? item.TranscriptLink.replace(/https?:\/\/rmacerts\.ripov-edu\.kz\/api\/pdf\//, '') : null;
            if (!trimmedTranscriptUrl) {
                trimmedTranscriptUrl = null;
            }
            if (item.phoneNumber != null && trimmedUrl != null) {
                setIssuingStatus(true);
                const waMessageRes = await axios.get(`https://rmacerts.ripov-edu.kz/wa/send-message?number=${item.phoneNumber}&fileUrl=${trimmedUrl}&transcript=${trimmedTranscriptUrl}`, { headers: waHeaders });
                
                if (waMessageRes.status >= 200 && waMessageRes.status < 300) {
                    alert("Успешно отправлено.");
                    item.isDocumentSent = "да";
                }
                setIssuingStatus(false);
            } else {
                alert("Проверьте номер телефона слушателя.");
            }
        } catch(error) {
            console.error(error);
        }
    }

    var rowNum = 1;
    return (
        <div className="px-2 pt-3">
            <div className="border rounded px-1">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Название</th>
                            <th scope="col">Тип</th>
                            <th scope="col">Специальность</th>
                            <th scope="col">Часы</th>
                            <th scope="col">Номер документа</th>
                            <th scope="col">Филиал</th>
                            <th scope="col">Отправлен на WhatsApp</th>
                            <th scope="col">Управление</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listenerCerts.map((item) => (
                            <tr key={item.Id}>
                                <th scope="row">{rowNum++}</th>
                                <td>{item.CourseName}</td>
                                <td>{item.TypeName}</td>
                                <td>{item.SpecialtyName}</td>
                                <td>{item.Hours}</td>
                                <td>{item.DocumentNumber}</td>
                                <td>{item.BranchName}</td>
                                <td>
                                    {
                                        item.isDocumentSent ? "да" : (
                                            <div className='d-flex align-items-center'>
                                                <span style={{color: "red"}}>нет</span> <br />
                                                <button className='btn btn-primary ms-2' onClick={(e) => handleWaSend(e, item)}>Отправить</button>
                                            </div> 
                                        )
                                    }
                                </td>
                                <td>
                                    <a className="ms-2" href={`${item.FileLink}`} target="_blank" rel="noopener noreferrer">Посмотреть</a>
                                    <a className='ps-2' href="#" onClick={(e) => handleOpenModal(e, item)} rel="noopener noreferrer">Изменить</a>
                                    {
                                        item.TranscriptLink != null ? (
                                            <a className="ms-2" href={`${item.TranscriptLink}`} target="_blank" rel="noopener noreferrer">Транскрипт</a>
                                        ) : (<span></span>)
                                    }
                                    {/* {
                                        localStorage.getItem('role') == 'admin' ? (
                                            <a className='' href="#" onClick={(e) => handleDelete(e, item.Id)} target="_blank" rel="noopener noreferrer">Удалить</a>
                                        ) : (<span></span>)
                                    } */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <CertModalChange show={showModal2} data={selectedCourse} issuingStatus={issuingStatus} setIssuingStatus={setIssuingStatus} onClose={handleCloseModal} />
            </div>
        </div>
    );
}

export default ListenerCerts;